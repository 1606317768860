import logo from "../assets/logo.png";

const HayleyArthur = () => {
  return (
    <div
      className="app"
      style={{
        padding: 15,
        textAlign: "center",
      }}
    >
      <h1
        style={{
          color: "#2c898a",
          margin: 0,
        }}
      >
        Hayley Arthur
      </h1>

      <a href="mailto:4hearthur42@gmail.com">4hearthur42@gmail.com</a>

      <p
        style={{
          paddingTop: 20,
        }}
      >
        <h3 style={{ padding: 0, margin: 0 }}>
          American English Speech Coach:
        </h3>

        <ul
          style={{
            display: "inline-block",
            textAlign: "left",
          }}
        >
          <li>Accent Reduction</li>
          <li>Confidence in Casual Conversation</li>
          <li>Public Speaking</li>
          <li>Interview Prep</li>
          <li>Business English</li>
        </ul>
      </p>

      <p></p>

      <p>
        <div
          style={{
            padding: "10px 0",
          }}
        >
          <img
            src={logo}
            alt="Hayleys English"
            style={{
              width: 100,
            }}
          />
        </div>

        <a href="https://hayleysenglish.com">hayleysenglish.com</a>
      </p>
    </div>
  );
};

export default HayleyArthur;
