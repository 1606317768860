import "./App.css";
import logo from "./assets/logo.png";
import { ReactComponent as AppStore } from "./assets/app-store-badge-black.svg";
import { ReactComponent as GooglePlayStore } from "./assets/google-play-badge.svg";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HayleyArthur from "./pages/HayleyArthur";

const App = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="hayley-arthur" element={<HayleyArthur />} />
      {/* <Route path="*" element={<NoMatch />} /> */}
    </Routes>
  );
};

const Home = () => {
  return (
    <div className="app">
      <img src={logo} alt="Hayleys English" className="logo" />

      <div className="space" />

      <ul className="store-list">
        <li>
          <a
            href="https://apps.apple.com/app/id1590216319"
            target="_blank"
            rel="noreferrer"
          >
            <AppStore className="badge" />
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.hayleysenglish.perfectyourenglish"
            target="_blank"
            rel="noreferrer"
          >
            <GooglePlayStore className="badge" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default App;
