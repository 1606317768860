const PrivacyPolicy = () => {
  return (
    <>
      <div data-custom-class="body">
        <div>
          <strong>
            <span style={{ fontSize: 26 }}>
              <span data-custom-class="title">
                <div className="block-component"></div>
                <div className="question">PRIVACY POLICY</div>
                <div className="statement-end-if-in-editor"></div>
              </span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="subtitle">
                  Last updated <div className="question">June 14, 2023</div>
                </span>
              </span>
            </strong>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                This privacy notice for{" "}
                <div className="question">
                  Hayley's English Advanced Rapid Training, LLC
                  <div className="block-component"></div>
                </div>
                (<div className="block-component"></div>"
                <div className="block-component"></div>
                <strong>Company</strong>
                <div className="statement-end-if-in-editor"></div>
                ," "<strong>we</strong>
                ," "<strong>us</strong>
                ," or "<strong>our</strong>"
                <div className="statement-end-if-in-editor"></div>
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or
                share (<div className="block-component"></div>"
                <strong>process</strong>"
                <div className="statement-end-if-in-editor"></div>) your
                information when you use our services (
                <div className="block-component"></div>"
                <strong>Services</strong>"
                <div className="statement-end-if-in-editor"></div>
                ), such as when you:
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <div className="block-component"></div>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <div>
            <div className="block-component">
              <span style={{ fontSize: 15 }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component"></div>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Download and use<div className="block-component"></div>
                    our mobile application
                    <div className="block-component"></div>(
                    <div className="question">
                      Perfect your Prepositions)
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <div className="statement-end-if-in-editor">
                                        ,
                                      </div>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <div className="statement-end-if-in-editor">
                                <div className="block-component">
                                  or any other application of ours that links to
                                  this privacy notice
                                </div>
                              </div>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <div className="statement-end-if-in-editor"></div>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>
                  Reading this privacy notice will help you understand your
                  privacy rights and choices. If you do not agree with our
                  policies and practices, please do not use our Services. If you
                  still have any questions or concerns, please contact us at{" "}
                  <div className="question">heartadvancedenglish@gmail.com</div>
                  .
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#toc"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;below to find the section you are looking for.</em>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong>
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with{" "}
                <div className="block-component"></div>
                <div className="question">
                  Hayley's English Advanced Rapid Training, LLC
                </div>
                <div className="statement-end-if-in-editor"></div>
                and the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#personalinfo"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  personal information you disclose to us
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we process any sensitive personal information?
                </strong>
                <div className="block-component"></div>
                We do not process sensitive personal information.
                <div className="else-block"></div>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we receive any information from third parties?
                </strong>
                <div className="block-component"></div>
                We do not receive any information from third parties.
                <div className="else-block"></div>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infouse"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  how we process your information
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which{" "}
                  <div className="block-component"></div>
                  parties do we share personal information?
                </strong>
                We may share information in specific situations and with
                specific <div className="block-component"></div>
                third parties. Learn more about&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#whoshare"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  when and with whom we share your personal information
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                .<div className="block-component"></div>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong>
                We have <div className="block-component"></div>
                organizational<div className="statement-end-if-in-editor"></div>
                and technical processes and procedures in place to protect your
                personal information. However, no electronic transmission over
                the internet or information storage technology can be guaranteed
                to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other{" "}
                <div className="block-component"></div>
                unauthorized<div className="statement-end-if-in-editor"></div>
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Learn more about&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infosafe"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  how we keep your information safe
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <div className="statement-end-if-in-editor"></div>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong>
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information. Learn more about&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#privacyrights"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">your privacy rights</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong>
                The easiest way to exercise your rights is by{" "}
                <div className="block-component"></div>
                visiting{" "}
                <div className="question">heartadvancedenglish@gmail.com</div>
                <div className="else-block"></div>, or by contacting us. We will
                consider and act upon any request in accordance with applicable
                data protection laws.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                Want to learn more about what{" "}
                <div className="block-component"></div>
                <div className="question">
                  Hayley's English Advanced Rapid Training, LLC
                </div>
                <div className="statement-end-if-in-editor"></div>
                does with any information we collect?&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#toc"
            >
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Review the privacy notice in full
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">.</span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="toc" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infocollect"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infouse"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <div className="block-component"></div>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a
                  data-custom-class="link"
                  href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#whoshare"
                >
                  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>
              <span data-custom-class="body_text">
                <div className="block-component"></div>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <div className="block-component"></div>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <div className="block-component"></div>
                        </span>
                      </span>
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#inforetain"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  4. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <div className="block-component"></div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infosafe"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <div className="statement-end-if-in-editor"></div>
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#infominors"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  6. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <div className="statement-end-if-in-editor"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a
                  data-custom-class="link"
                  href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#privacyrights"
                >
                  7. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#DNT"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  8. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#caresidents"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </span>
            <div className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text"></span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a
                data-custom-class="link"
                href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#policyupdates"
              >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  10. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#contact"
            >
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a
              data-custom-class="link"
              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#request"
            >
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="infocollect" style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="personalinfo" style={{ lineHeight: "1.5" }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            We collect personal information that you provide to
                            us.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide
                  to us when you{" "}
                  <span style={{ fontSize: 15 }}>
                    <div className="block-component"></div>
                  </span>
                  register on the Services,&nbsp;
                </span>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <div className="statement-end-if-in-editor"></div>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    express an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <div className="block-component"></div>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>Personal Information Provided by You.</strong>
                  The personal information that we collect depends on the
                  context of your interactions with us and the Services, the
                  choices you make, and the products and features you use. The
                  personal information we collect may include the following:
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">names</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">phone numbers</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">email addresses</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">usernames</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">passwords</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="question">contact preferences</div>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="forloop-component"></div>
                    </span>
                    <span data-custom-class="body_text">
                      <div className="statement-end-if-in-editor"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>
                <div className="block-component"></div>
                We do not process sensitive information.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <div className="else-block"></div>
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>Payment Data.</strong>
                  We may collect data necessary to process your payment if you
                  make purchases, such as your payment instrument number, and
                  the security code associated with your payment instrument. All
                  payment data is stored by
                  <div className="forloop-component"></div>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <div className="block-component"></div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <div className="question">Google Play</div>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <div className="block-component"></div>
                                </span>
                              </span>
                            </span>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text">
                                                <div className="forloop-component"></div>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="block-component"></div>
                                                          and{" "}
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="question">
                                                                  App Store
                                                                </div>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <div className="statement-end-if-in-editor"></div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <div className="forloop-component"></div>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          . You may find their
                                                          privacy notice link(s)
                                                          here:
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="forloop-component"></div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div className="block-component"></div>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <div className="question">
                                                            <a
                                                              href="https://policies.google.com/privacy"
                                                              target="_blank"
                                                              data-custom-class="link"
                                                              rel="noreferrer"
                                                            >
                                                              https://policies.google.com/privacy
                                                            </a>
                                                          </div>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component"></div>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <div className="forloop-component"></div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div className="block-component"></div>
                                                                          and{" "}
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <div className="question">
                                                                                  <a
                                                                                    href="https://www.apple.com/legal/privacy/"
                                                                                    target="_blank"
                                                                                    data-custom-class="link"
                                                                                    rel="noreferrer"
                                                                                  >
                                                                                    https://www.apple.com/legal/privacy/
                                                                                  </a>
                                                                                </div>
                                                                              </span>
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <div className="statement-end-if-in-editor"></div>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <div className="forloop-component"></div>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              .
                                                                              <div className="block-component"></div>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <div className="statement-end-if-in-editor">
                        <div className="block-component"></div>
                      </div>
                    </span>
                  </span>
                </span>
              </span>
              <div className="block-component">
                <div className="block-component"></div>
              </div>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <strong>Application Data.</strong>
                If you use our application(s), we also may collect the following
                information if you choose to provide us with access or
                permission:
                <div className="block-component"></div>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <div className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text"></span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <div className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text"></span>
                  </span>
                </div>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Data.</em>
                      We automatically collect device information (such as your
                      mobile device ID, model, and manufacturer), operating
                      system, version information and system configuration
                      information, device and application identification
                      numbers, browser type and version, hardware model Internet
                      service provider and/or mobile carrier, and Internet
                      Protocol (IP) address (or proxy server). If you are using
                      our application(s), we may also collect information about
                      the phone network associated with your mobile device, your
                      mobile device’s operating system or platform, the type of
                      mobile device you use, your mobile device’s unique device
                      ID, and information about the features of our
                      application(s) you accessed.
                      <div className="statement-end-if-in-editor"></div>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <div className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text"></span>
                  </span>
                </div>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <em>Push Notifications.</em>
                      We may request to send you push notifications regarding
                      your account or certain features of the application(s). If
                      you wish to opt out from receiving these types of
                      communications, you may turn them off in your device's
                      settings.
                      <div className="statement-end-if-in-editor"></div>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    This information is primarily needed to maintain the
                    security and operation of our application(s), for
                    troubleshooting, and for our internal analytics and
                    reporting purposes.
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <div className="statement-end-if-in-editor">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text"></span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      All personal information that you provide to us must be
                      true, complete, and accurate, and you must notify us of
                      any changes to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span
                  data-custom-class="heading_2"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>Information automatically collected</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short:</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                Some information — such as your Internet
                                Protocol (IP) address and/or browser and device
                                characteristics — is collected automatically
                                when you visit our Services.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      We automatically collect certain information when you
                      visit, use, or navigate the Services. This information
                      does not reveal your specific identity (like your name or
                      contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about how and when you use our Services, and
                      other technical information. This information is primarily
                      needed to maintain the security and operation of our
                      Services, and for our internal analytics and reporting
                      purposes.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <div className="block-component"></div>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <div className="block-component"></div>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      The information we collect includes:
                      <div className="block-component"></div>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <em>Log and Usage Data.</em>
                        Log and usage data is service-related, diagnostic,
                        usage, and performance information our servers
                        automatically collect when you access or use our
                        Services and which we record in log files. Depending on
                        how you interact with us, this log data may include your
                        IP address, device information, browser type, and
                        settings and information about your activity in the
                        Services<span style={{ fontSize: 15 }}>&nbsp;</span>
                        (such as the date/time stamps associated with your
                        usage, pages and files viewed, searches, and other
                        actions you take such as which features you use), device
                        event information (such as system activity, error
                        reports (sometimes called{" "}
                        <div className="block-component"></div>
                        "crash dumps"
                        <div className="statement-end-if-in-editor"></div>
                        ), and hardware settings).
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <div className="statement-end-if-in-editor"></div>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <div className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text"></span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <div className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text"></span>
                    </span>
                  </div>
                  <div>
                    <div className="block-component">
                      <span style={{ fontSize: 15 }}></span>
                    </div>
                    <div className="statement-end-if-in-editor"></div>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span
                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                          >
                            <span data-custom-class="body_text">
                              <div className="statement-end-if-in-editor">
                                <div className="block-component"></div>
                              </div>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <div className="block-component"></div>
                      </span>
                    </span>
                  </div>
                  <div id="infouse" style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              id="control"
                              style={{ color: "rgb(0, 0, 0)" }}
                            >
                              <strong>
                                <span data-custom-class="heading_1">
                                  2. HOW DO WE PROCESS YOUR INFORMATION?
                                </span>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <div style={{ lineHeight: "1.5" }}>
                      <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    <em>In Short:&nbsp;</em>
                                  </strong>
                                  <em>
                                    We process your information to provide,
                                    improve, and administer our Services,
                                    communicate with you, for security and fraud
                                    prevention, and to comply with law. We may
                                    also process your information for other
                                    purposes with your consent.
                                  </em>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            We process your personal information for a variety
                            of reasons, depending on how you interact with our
                            Services, including:
                          </strong>
                          <div className="block-component"></div>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              To facilitate account creation and authentication
                              and otherwise manage user accounts.&nbsp;
                            </strong>
                            We may process your information so you can create
                            and log in to your account, as well as keep your
                            account in working order.
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <div className="statement-end-if-in-editor"></div>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component"></div>
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              To deliver and facilitate delivery of services to
                              the user.&nbsp;
                            </strong>
                            We may process your information to provide you with
                            the requested service.
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <div className="statement-end-if-in-editor"></div>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <div className="block-component"></div>
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <div className="block-component"></div>
                          </span>
                        </span>
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <div className="block-component"></div>
                            </span>
                          </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                          <div className="block-component">
                            <span style={{ fontSize: 15 }}></span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <div className="block-component">
                              <span style={{ fontSize: 15 }}></span>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <div className="block-component">
                                <span style={{ fontSize: 15 }}></span>
                              </div>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To{" "}
                                        <div className="block-component"></div>
                                        fulfill
                                        <div className="statement-end-if-in-editor"></div>
                                        and manage your orders.&nbsp;
                                      </strong>
                                      We may process your information to{" "}
                                      <div className="block-component"></div>
                                      fulfill
                                      <div className="statement-end-if-in-editor"></div>
                                      and manage your orders, payments, returns,
                                      and exchanges made through the Services.
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="statement-end-if-in-editor"></div>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <div className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text"></span>
                                </span>
                              </div>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }}></span>
                                </div>
                              </p>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            To enable user-to-user
                                            communications.&nbsp;
                                          </strong>
                                          We may process your information if you
                                          choose to use any of our offerings
                                          that allow for communication with
                                          another user.
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="statement-end-if-in-editor"></div>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }}></span>
                                </div>
                              </p>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <div className="block-component"></div>
                              </p>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            To request feedback.&nbsp;
                                          </strong>
                                          We may process your information when
                                          necessary to request feedback and to
                                          contact you about your use of our
                                          Services.
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <div className="statement-end-if-in-editor"></div>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                <div className="block-component"></div>
                              </p>
                              <div style={{ lineHeight: "1.5" }}>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text"></span>
                                  </span>
                                </div>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To send you marketing and promotional
                                        communications.&nbsp;
                                      </strong>
                                      We may process the personal information
                                      you send to us for our marketing purposes,
                                      if this is in accordance with your
                                      marketing preferences. You can opt out of
                                      our marketing emails at any time. For more
                                      information, see{" "}
                                      <div className="block-component"></div>"
                                      <div className="statement-end-if-in-editor"></div>
                                    </span>
                                  </span>
                                  <a href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#privacyrights">
                                    <span data-custom-class="link">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="link">
                                          WHAT ARE YOUR PRIVACY RIGHTS?
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <div className="block-component"></div>"
                                      <div className="statement-end-if-in-editor"></div>
                                      below.
                                    </span>
                                    <div className="statement-end-if-in-editor">
                                      <span data-custom-class="body_text"></span>
                                    </div>
                                  </span>
                                </li>
                              </ul>
                              <div style={{ lineHeight: "1.5" }}>
                                <div className="block-component">
                                  <span style={{ fontSize: 15 }}></span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                  <div className="block-component">
                                    <span style={{ fontSize: 15 }}></span>
                                  </div>
                                </div>
                                <ul>
                                  <li style={{ lineHeight: "1.5" }}>
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>
                                            To deliver targeted advertising to
                                            you.&nbsp;
                                          </strong>
                                          We may process your information to
                                          develop and display{" "}
                                          <div className="block-component"></div>
                                          personalized
                                          <div className="statement-end-if-in-editor"></div>
                                          content and advertising tailored to
                                          your interests, location, and more.
                                          <div className="block-component"></div>
                                        </span>
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                                <div style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <div className="block-component">
                                      <span data-custom-class="body_text"></span>
                                    </div>
                                  </span>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <div className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text"></span>
                                      </span>
                                    </div>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <div className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text"></span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <div className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text"></span>
                                          </span>
                                        </div>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <div className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text"></span>
                                            </span>
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <div className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </div>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    To evaluate and improve our
                                                    Services, products,
                                                    marketing, and your
                                                    experience.
                                                  </strong>
                                                  We may process your
                                                  information when we believe it
                                                  is necessary to identify usage
                                                  trends, determine the
                                                  effectiveness of our
                                                  promotional campaigns, and to
                                                  evaluate and improve our
                                                  Services, products, marketing,
                                                  and your experience.
                                                </span>
                                              </span>
                                              <div className="statement-end-if-in-editor">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </div>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <div className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text"></span>
                                              </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <div className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text"></span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <div className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </div>
                                              </div>
                                              <ul>
                                                <li
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        To determine the
                                                        effectiveness of our
                                                        marketing and
                                                        promotional campaigns.
                                                      </strong>
                                                      We may process your
                                                      information to better
                                                      understand how to provide
                                                      marketing and promotional
                                                      campaigns that are most
                                                      relevant to you.
                                                      <div className="statement-end-if-in-editor"></div>
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <div className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <div className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text"></span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <div className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text"></span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <div className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </div>
                                                      <div className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </div>
                                                      <div className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </div>
                                                      <div className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text"></span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="whoshare"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(0, 0, 0)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    3. WHEN AND
                                                                    WITH WHOM DO
                                                                    WE SHARE
                                                                    YOUR
                                                                    PERSONAL
                                                                    INFORMATION?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                              &nbsp;We may share
                                                              information in
                                                              specific
                                                              situations
                                                              described in this
                                                              section and/or
                                                              with the following{" "}
                                                              <div className="block-component"></div>
                                                              third parties.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <div className="block-component"></div>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We{" "}
                                                          <div className="block-component"></div>
                                                          may need to share your
                                                          personal information
                                                          in the following
                                                          situations:
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              Business
                                                              Transfers.
                                                            </strong>
                                                            We may share or
                                                            transfer your
                                                            information in
                                                            connection with, or
                                                            during negotiations
                                                            of, any merger, sale
                                                            of company assets,
                                                            financing, or
                                                            acquisition of all
                                                            or a portion of our
                                                            business to another
                                                            company.
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <div className="block-component"></div>
                                                        </span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <div className="block-component">
                                                            <span data-custom-class="body_text"></span>
                                                          </div>
                                                        </span>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <div className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <strong>
                                                                  Business
                                                                  Partners.
                                                                </strong>
                                                                We may share
                                                                your information
                                                                with our
                                                                business
                                                                partners to
                                                                offer you
                                                                certain
                                                                products,
                                                                services, or
                                                                promotions.
                                                              </span>
                                                            </span>
                                                            <div className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <div className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text"></span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <div className="block-component"></div>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    Offer
                                                                    Wall.&nbsp;
                                                                  </strong>
                                                                  Our
                                                                  application(s)
                                                                  may display a
                                                                  third-party
                                                                  hosted{" "}
                                                                  <div className="block-component"></div>
                                                                  "offer wall."
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  Such an offer
                                                                  wall allows
                                                                  third-party
                                                                  advertisers to
                                                                  offer virtual
                                                                  currency,
                                                                  gifts, or
                                                                  other items to
                                                                  users in
                                                                  return for the
                                                                  acceptance and
                                                                  completion of
                                                                  an
                                                                  advertisement
                                                                  offer. Such an
                                                                  offer wall may
                                                                  appear in our
                                                                  application(s)
                                                                  and be
                                                                  displayed to
                                                                  you based on
                                                                  certain data,
                                                                  such as your
                                                                  geographic
                                                                  area or
                                                                  demographic
                                                                  information.
                                                                  When you click
                                                                  on an offer
                                                                  wall, you will
                                                                  be brought to
                                                                  an external
                                                                  website
                                                                  belonging to
                                                                  other persons
                                                                  and will leave
                                                                  our
                                                                  application(s).
                                                                  A unique
                                                                  identifier,
                                                                  such as your
                                                                  user ID, will
                                                                  be shared with
                                                                  the offer wall
                                                                  provider in
                                                                  order to
                                                                  prevent fraud
                                                                  and properly
                                                                  credit your
                                                                  account with
                                                                  the relevant
                                                                  reward.
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                </span>
                                                              </span>
                                                              <div className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text"></span>
                                                                </span>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <div className="block-component">
                                                                        <span data-custom-class="heading_1"></span>
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <div className="block-component"></div>
                                                                            </span>
                                                                            <div className="block-component">
                                                                              <span data-custom-class="body_text">
                                                                                <div className="block-component"></div>
                                                                              </span>
                                                                            </div>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="inforetain"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          4. HOW
                                                                          LONG
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We keep your
                                                                    information
                                                                    for as long
                                                                    as necessary
                                                                    to{" "}
                                                                    <div className="block-component"></div>
                                                                    fulfill
                                                                    <div className="statement-end-if-in-editor"></div>
                                                                    the purposes
                                                                    outlined in
                                                                    this privacy
                                                                    notice
                                                                    unless
                                                                    otherwise
                                                                    required by
                                                                    law.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will only
                                                                  keep your
                                                                  personal
                                                                  information
                                                                  for as long as
                                                                  it is
                                                                  necessary for
                                                                  the purposes
                                                                  set out in
                                                                  this privacy
                                                                  notice, unless
                                                                  a longer
                                                                  retention
                                                                  period is
                                                                  required or
                                                                  permitted by
                                                                  law (such as
                                                                  tax,
                                                                  accounting, or
                                                                  other legal
                                                                  requirements).
                                                                  <div className="block-component"></div>
                                                                  No purpose in
                                                                  this notice
                                                                  will require
                                                                  us keeping
                                                                  your personal
                                                                  information
                                                                  for longer
                                                                  than{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="block-component"></div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <div className="block-component"></div>
                                                                  the period of
                                                                  time in which
                                                                  users have an
                                                                  account with
                                                                  us
                                                                  <div className="block-component"></div>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="else-block"></div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  When we have
                                                                  no ongoing
                                                                  legitimate
                                                                  business need
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  we will either
                                                                  delete or{" "}
                                                                  <div className="block-component"></div>
                                                                  anonymize
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  such
                                                                  information,
                                                                  or, if this is
                                                                  not possible
                                                                  (for example,
                                                                  because your
                                                                  personal
                                                                  information
                                                                  has been
                                                                  stored in
                                                                  backup
                                                                  archives),
                                                                  then we will
                                                                  securely store
                                                                  your personal
                                                                  information
                                                                  and isolate it
                                                                  from any
                                                                  further
                                                                  processing
                                                                  until deletion
                                                                  is possible.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <div className="block-component"></div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infosafe"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          5. HOW
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION
                                                                          SAFE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We aim to
                                                                    protect your
                                                                    personal
                                                                    information
                                                                    through a
                                                                    system of{" "}
                                                                    <div className="block-component"></div>
                                                                    organizational
                                                                    <div className="statement-end-if-in-editor"></div>
                                                                    and
                                                                    technical
                                                                    security
                                                                    measures.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We have
                                                                  implemented
                                                                  appropriate
                                                                  and reasonable
                                                                  technical and{" "}
                                                                  <div className="block-component"></div>
                                                                  organizational
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  security
                                                                  measures
                                                                  designed to
                                                                  protect the
                                                                  security of
                                                                  any personal
                                                                  information we
                                                                  process.
                                                                  However,
                                                                  despite our
                                                                  safeguards and
                                                                  efforts to
                                                                  secure your
                                                                  information,
                                                                  no electronic
                                                                  transmission
                                                                  over the
                                                                  Internet or
                                                                  information
                                                                  storage
                                                                  technology can
                                                                  be guaranteed
                                                                  to be 100%
                                                                  secure, so we
                                                                  cannot promise
                                                                  or guarantee
                                                                  that hackers,
                                                                  cybercriminals,
                                                                  or other{" "}
                                                                  <div className="block-component"></div>
                                                                  unauthorized
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  third parties
                                                                  will not be
                                                                  able to defeat
                                                                  our security
                                                                  and improperly
                                                                  collect,
                                                                  access, steal,
                                                                  or modify your
                                                                  information.
                                                                  Although we
                                                                  will do our
                                                                  best to
                                                                  protect your
                                                                  personal
                                                                  information,
                                                                  transmission
                                                                  of personal
                                                                  information to
                                                                  and from our
                                                                  Services is at
                                                                  your own risk.
                                                                  You should
                                                                  only access
                                                                  the Services
                                                                  within a
                                                                  secure
                                                                  environment.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <div className="statement-end-if-in-editor"></div>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="block-component"></div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infominors"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          6. DO
                                                                          WE
                                                                          COLLECT
                                                                          INFORMATION
                                                                          FROM
                                                                          MINORS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We do
                                                                    not
                                                                    knowingly
                                                                    collect data
                                                                    from or
                                                                    market to{" "}
                                                                    <div className="block-component"></div>
                                                                    children
                                                                    under 18
                                                                    years of age
                                                                    <div className="else-block"></div>
                                                                    .
                                                                  </em>
                                                                  <div className="block-component"></div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We do not
                                                                  knowingly
                                                                  solicit data
                                                                  from or market
                                                                  to children
                                                                  under 18 years
                                                                  of age. By
                                                                  using the
                                                                  Services, you
                                                                  represent that
                                                                  you are at
                                                                  least 18 or
                                                                  that you are
                                                                  the parent or
                                                                  guardian of
                                                                  such a minor
                                                                  and consent to
                                                                  such minor
                                                                  dependent’s
                                                                  use of the
                                                                  Services. If
                                                                  we learn that
                                                                  personal
                                                                  information
                                                                  from users
                                                                  less than 18
                                                                  years of age
                                                                  has been
                                                                  collected, we
                                                                  will
                                                                  deactivate the
                                                                  account and
                                                                  take
                                                                  reasonable
                                                                  measures to
                                                                  promptly
                                                                  delete such
                                                                  data from our
                                                                  records. If
                                                                  you become
                                                                  aware of any
                                                                  data we may
                                                                  have collected
                                                                  from children
                                                                  under age 18,
                                                                  please contact
                                                                  us at{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="block-component"></div>
                                                                      <div className="question">
                                                                        heartadvancedenglish@gmail.com
                                                                      </div>
                                                                      <div className="else-block"></div>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <div className="else-block">
                                                                    <div className="block-component"></div>
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="privacyrights"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          7.
                                                                          WHAT
                                                                          ARE
                                                                          YOUR
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <div className="block-component"></div>
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                      &nbsp;
                                                                    </span>
                                                                    You may
                                                                    review,
                                                                    change, or
                                                                    terminate
                                                                    your account
                                                                    at any time.
                                                                  </em>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <div className="block-component"></div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              &nbsp;
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in the
                                                                  EEA or UK and
                                                                  you believe we
                                                                  are unlawfully
                                                                  processing
                                                                  your personal
                                                                  information,
                                                                  you also have
                                                                  the right to
                                                                  complain to
                                                                  your{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(48, 48, 241)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                Member
                                                                                State
                                                                                data
                                                                                protection
                                                                                authority
                                                                              </span>
                                                                            </a>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  or&nbsp;
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              <span data-custom-class="link">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      UK data
                                                                      protection
                                                                      authority
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in
                                                                  Switzerland,
                                                                  you may
                                                                  contact the{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(48, 48, 241)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <a
                                                                                data-custom-class="link"
                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                              >
                                                                                Federal
                                                                                Data
                                                                                Protection
                                                                                and
                                                                                Information
                                                                                Commissioner
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="withdrawconsent"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Withdrawing
                                                                      your
                                                                      consent:
                                                                    </u>
                                                                  </strong>
                                                                  If we are
                                                                  relying on
                                                                  your consent
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  <div className="block-component"></div>
                                                                  which may be
                                                                  express and/or
                                                                  implied
                                                                  consent
                                                                  depending on
                                                                  the applicable
                                                                  law,
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  you have the
                                                                  right to
                                                                  withdraw your
                                                                  consent at any
                                                                  time. You can
                                                                  withdraw your
                                                                  consent at any
                                                                  time by
                                                                  contacting us
                                                                  by using the
                                                                  contact
                                                                  details
                                                                  provided in
                                                                  the section{" "}
                                                                  <div className="block-component"></div>
                                                                  "
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    HOW CAN YOU
                                                                    CONTACT US
                                                                    ABOUT THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div className="block-component"></div>
                                                                  "
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  below
                                                                  <div className="block-component"></div>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                However, please
                                                                note that this
                                                                will not affect
                                                                the lawfulness
                                                                of the
                                                                processing
                                                                before its
                                                                withdrawal nor,
                                                                <div className="block-component"></div>
                                                                when applicable
                                                                law allows,
                                                                <div className="statement-end-if-in-editor"></div>
                                                                will it affect
                                                                the processing
                                                                of your personal
                                                                information
                                                                conducted in
                                                                reliance on
                                                                lawful
                                                                processing
                                                                grounds other
                                                                than consent.
                                                                <div className="block-component"></div>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <u>
                                                                    Opting out
                                                                    of marketing
                                                                    and
                                                                    promotional
                                                                    communications:
                                                                  </u>
                                                                </strong>
                                                                <strong>
                                                                  <u>&nbsp;</u>
                                                                </strong>
                                                                You can
                                                                unsubscribe from
                                                                our marketing
                                                                and promotional
                                                                communications
                                                                at any time by
                                                                <div className="block-component"></div>
                                                                clicking on the
                                                                unsubscribe link
                                                                in the emails
                                                                that we send,
                                                                <div className="statement-end-if-in-editor"></div>
                                                                <div className="block-component"></div>
                                                                <div className="block-component"></div>
                                                                or by contacting
                                                                us using the
                                                                details provided
                                                                in the section{" "}
                                                                <div className="block-component"></div>
                                                                "
                                                                <div className="statement-end-if-in-editor"></div>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="https://app.termly.io/dashboard/website/7ae81d4d-07f0-43a0-9ea3-6531d7fb1863/privacy-policy#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  HOW CAN YOU
                                                                  CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="block-component"></div>
                                                                "
                                                                <div className="statement-end-if-in-editor"></div>
                                                                below. You will
                                                                then be removed
                                                                from the
                                                                marketing lists.
                                                                However, we may
                                                                still
                                                                communicate with
                                                                you — for
                                                                example, to send
                                                                you
                                                                service-related
                                                                messages that
                                                                are necessary
                                                                for the
                                                                administration
                                                                and use of your
                                                                account, to
                                                                respond to
                                                                service
                                                                requests, or for
                                                                other
                                                                non-marketing
                                                                purposes.
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <div className="statement-end-if-in-editor"></div>
                                                              </span>
                                                            </span>
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="heading_2">
                                                                <strong>
                                                                  Account
                                                                  Information
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                If you would at
                                                                any time like to
                                                                review or change
                                                                the information
                                                                in your account
                                                                or terminate
                                                                your account,
                                                                you can:
                                                                <div className="forloop-component"></div>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <div className="question">
                                                                    Contact us
                                                                    using the
                                                                    contact
                                                                    information
                                                                    provided.
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <div className="forloop-component"></div>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Upon your
                                                                request to
                                                                terminate your
                                                                account, we will
                                                                deactivate or
                                                                delete your
                                                                account and
                                                                information from
                                                                our active
                                                                databases.
                                                                However, we may
                                                                retain some
                                                                information in
                                                                our files to
                                                                prevent fraud,
                                                                troubleshoot
                                                                problems, assist
                                                                with any
                                                                investigations,
                                                                enforce our
                                                                legal terms
                                                                and/or comply
                                                                with applicable
                                                                legal
                                                                requirements.
                                                              </span>
                                                            </span>
                                                            <div className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <div className="block-component"></div>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                If you have
                                                                questions or
                                                                comments about
                                                                your privacy
                                                                rights, you may
                                                                email us at{" "}
                                                                <div className="question">
                                                                  heartadvancedenglish@gmail.com
                                                                </div>
                                                                .
                                                              </span>
                                                            </span>
                                                            <div className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="DNT"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          8.
                                                                          CONTROLS
                                                                          FOR
                                                                          DO-NOT-TRACK
                                                                          FEATURES
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Most web
                                                                  browsers and
                                                                  some mobile
                                                                  operating
                                                                  systems and
                                                                  mobile
                                                                  applications
                                                                  include a
                                                                  Do-Not-Track (
                                                                  <div className="block-component"></div>
                                                                  "DNT"
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  ) feature or
                                                                  setting you
                                                                  can activate
                                                                  to signal your
                                                                  privacy
                                                                  preference not
                                                                  to have data
                                                                  about your
                                                                  online
                                                                  browsing
                                                                  activities
                                                                  monitored and
                                                                  collected. At
                                                                  this stage no
                                                                  uniform
                                                                  technology
                                                                  standard for{" "}
                                                                  <div className="block-component"></div>
                                                                  recognizing
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  and
                                                                  implementing
                                                                  DNT signals
                                                                  has been{" "}
                                                                  <div className="block-component"></div>
                                                                  finalized
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  . As such, we
                                                                  do not
                                                                  currently
                                                                  respond to DNT
                                                                  browser
                                                                  signals or any
                                                                  other
                                                                  mechanism that
                                                                  automatically
                                                                  communicates
                                                                  your choice
                                                                  not to be
                                                                  tracked
                                                                  online. If a
                                                                  standard for
                                                                  online
                                                                  tracking is
                                                                  adopted that
                                                                  we must follow
                                                                  in the future,
                                                                  we will inform
                                                                  you about that
                                                                  practice in a
                                                                  revised
                                                                  version of
                                                                  this privacy
                                                                  notice.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="caresidents"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          9. DO
                                                                          CALIFORNIA
                                                                          RESIDENTS
                                                                          HAVE
                                                                          SPECIFIC
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    Yes, if you
                                                                    are a
                                                                    resident of
                                                                    California,
                                                                    you are
                                                                    granted
                                                                    specific
                                                                    rights
                                                                    regarding
                                                                    access to
                                                                    your
                                                                    personal
                                                                    information.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  California
                                                                  Civil Code
                                                                  Section
                                                                  1798.83, also
                                                                  known as the{" "}
                                                                  <div className="block-component"></div>
                                                                  "Shine The
                                                                  Light"
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  law, permits
                                                                  our users who
                                                                  are California
                                                                  residents to
                                                                  request and
                                                                  obtain from
                                                                  us, once a
                                                                  year and free
                                                                  of charge,
                                                                  information
                                                                  about
                                                                  categories of
                                                                  personal
                                                                  information
                                                                  (if any) we
                                                                  disclosed to
                                                                  third parties
                                                                  for direct
                                                                  marketing
                                                                  purposes and
                                                                  the names and
                                                                  addresses of
                                                                  all third
                                                                  parties with
                                                                  which we
                                                                  shared
                                                                  personal
                                                                  information in
                                                                  the
                                                                  immediately
                                                                  preceding
                                                                  calendar year.
                                                                  If you are a
                                                                  California
                                                                  resident and
                                                                  would like to
                                                                  make such a
                                                                  request,
                                                                  please submit
                                                                  your request
                                                                  in writing to
                                                                  us using the
                                                                  contact
                                                                  information
                                                                  provided
                                                                  below.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  under 18 years
                                                                  of age, reside
                                                                  in California,
                                                                  and have a
                                                                  registered
                                                                  account with
                                                                  Services, you
                                                                  have the right
                                                                  to request
                                                                  removal of
                                                                  unwanted data
                                                                  that you
                                                                  publicly post
                                                                  on the
                                                                  Services. To
                                                                  request
                                                                  removal of
                                                                  such data,
                                                                  please contact
                                                                  us using the
                                                                  contact
                                                                  information
                                                                  provided below
                                                                  and include
                                                                  the email
                                                                  address
                                                                  associated
                                                                  with your
                                                                  account and a
                                                                  statement that
                                                                  you reside in
                                                                  California. We
                                                                  will make sure
                                                                  the data is
                                                                  not publicly
                                                                  displayed on
                                                                  the Services,
                                                                  but please be
                                                                  aware that the
                                                                  data may not
                                                                  be completely
                                                                  or
                                                                  comprehensively
                                                                  removed from
                                                                  all our
                                                                  systems (e.g.
                                                                  <div className="block-component"></div>
                                                                  ,
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  backups,
                                                                  etc.).
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="block-component"></div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              ></span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="policyupdates"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          10. DO
                                                                          WE
                                                                          MAKE
                                                                          UPDATES
                                                                          TO
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <strong>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </strong>
                                                                    Yes, we will
                                                                    update this
                                                                    notice as
                                                                    necessary to
                                                                    stay
                                                                    compliant
                                                                    with
                                                                    relevant
                                                                    laws.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may update
                                                                  this privacy
                                                                  notice from
                                                                  time to time.
                                                                  The updated
                                                                  version will
                                                                  be indicated
                                                                  by an updated{" "}
                                                                  <div className="block-component"></div>
                                                                  "Revised"
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  date and the
                                                                  updated
                                                                  version will
                                                                  be effective
                                                                  as soon as it
                                                                  is accessible.
                                                                  If we make
                                                                  material
                                                                  changes to
                                                                  this privacy
                                                                  notice, we may
                                                                  notify you
                                                                  either by
                                                                  prominently
                                                                  posting a
                                                                  notice of such
                                                                  changes or by
                                                                  directly
                                                                  sending you a
                                                                  notification.
                                                                  We encourage
                                                                  you to review
                                                                  this privacy
                                                                  notice
                                                                  frequently to
                                                                  be informed of
                                                                  how we are
                                                                  protecting
                                                                  your
                                                                  information.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="contact"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          11.
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          CONTACT
                                                                          US
                                                                          ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you have
                                                                  questions or
                                                                  comments about
                                                                  this notice,
                                                                  you may{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="block-component"></div>
                                                                      email us
                                                                      at{" "}
                                                                      <div className="question">
                                                                        heartadvancedenglish@gmail.com
                                                                      </div>
                                                                      <div className="statement-end-if-in-editor"></div>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        &nbsp;or
                                                                        contact
                                                                        us by
                                                                        post at:
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <div className="question">
                                                                            Hayley's
                                                                            English
                                                                            Advanced
                                                                            Rapid
                                                                            Training,
                                                                            LLC
                                                                          </div>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <div className="block-component"></div>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="question">
                                                                  __________
                                                                </div>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <div className="block-component"></div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <div className="question">
                                                                  __________
                                                                </div>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <div className="block-component"></div>
                                                                    <div className="block-component"></div>
                                                                    <div className="block-component"></div>
                                                                    <div className="block-component"></div>
                                                                    <div className="block-component"></div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <div className="question">
                                                                United States
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <div className="statement-end-if-in-editor"></div>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </span>
                                                            <div className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text"></span>
                                                              </span>
                                                            </div>
                                                            <div className="statement-end-if-in-editor"></div>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <div className="statement-end-if-in-editor">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <div className="block-component">
                                                                              <div className="block-component"></div>
                                                                            </div>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <div className="statement-end-if-in-editor">
                                                                          <div className="block-component"></div>
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="request"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          12.
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          REVIEW,
                                                                          UPDATE,
                                                                          OR
                                                                          DELETE
                                                                          THE
                                                                          DATA
                                                                          WE
                                                                          COLLECT
                                                                          FROM
                                                                          YOU?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <div className="block-component"></div>
                                                                  You have the
                                                                  right to
                                                                  request access
                                                                  to the
                                                                  personal
                                                                  information we
                                                                  collect from
                                                                  you, change
                                                                  that
                                                                  information,
                                                                  or delete it.{" "}
                                                                  <div className="statement-end-if-in-editor"></div>
                                                                  To request to
                                                                  review,
                                                                  update, or
                                                                  delete your
                                                                  personal
                                                                  information,
                                                                  please{" "}
                                                                  <div className="block-component"></div>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  visit:{" "}
                                                                  <div className="question">
                                                                    heartadvancedenglish@gmail.com
                                                                  </div>
                                                                  <div className="else-block"></div>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                .
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy